<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/07/Contact-DWF5-Rev.6-210610-DEF-1024x696.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">

                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        CONTACT DWF5 is the evolution of CONTACT ST5 model, but it is equipped with a
                                        double system of wire feeding. In fact, this model can provide two wires of
                                        different color and/or section to improve the performaces of this machine. Its
                                        standard features are the same of CONTACT ST5 model. </p>
                                </div>
                                <br />
                                <div class="mt-4">

                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        CONTACT DWF5 can be equipped up to three terminating units and up to two seal or
                                        sleeve insertion units and tinning stations. As each model of ST5 SERIES its
                                        crimping units (20 kN – 30 kN) can be integrated with a crimping force
                                        monitoring to guarantee a perfect quality and at the same time to give
                                        information about the output. Our system doesn’t reduce in any way the piece
                                        output of the machine. Another option available is our Dual device that allows
                                        double crimp connections in either a horizontal and a vertical arrangement as
                                        required. It can be equipped with a wire feeding system by four rollers
                                        (standard feature) or a wire feeding system by belts (on request). The user’s
                                        interface, on Windows, oversees all different functions, making our CONTACT DWF5
                                        a powerful but userfriendly machine. To reduce the adjustment times, an
                                        unlimited number of different part numbers can easily be stored and retrieved,
                                        all settings are electronicallyn performed and all parameters are automatically
                                        optimized. </p>
                                </div>
                                <br />




                                <p style="font-size: 14px; color: black; font-weight: 400">
                                    Data and measurements are indicative and subject to change
                                    without notice. Some particular types of cables may not be
                                    machined even though they fall within the range of sections
                                    indicated. KM Corporate will still be happy to carry out tests
                                    and supply wire-worked samples
                                </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br />
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-5"></div>
                            <div class="col-7">
                                <button @click="file()" class="btn btn-primary" style="width:100%"><i
                                        class="fa fa-download"></i> Download The Technical Sheet</button>
                            </div>
                        </div>
                        <br><br><br>
                        <div class="row">
                            <h1 style="color: black; font-weight: 800; margin-left: 40px" class="font-size-20 mb-3">
                                Gallery

                            </h1>

                        </div>
                        <br />

                        <div class="row" style="margin-left:50px;">

                            <vue-picture-swipe :items="items"></vue-picture-swipe>
                        </div>
                        <!-- end row -->

                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {

        Header,
        Footer,


    },
    data() {
        return {
            items: [{
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Contact-DWF5-Rev.6-210610-DEF-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Contact-DWF5-Rev.6-210610-DEF-150x150.jpg',
                w: 1200,
                h: 900,
                alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/08/CONTACT-DWF5-PIANTA-rev.-3-210802-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/08/CONTACT-DWF5-PIANTA-rev.-3-210802-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/08/contact-dwf5-optional-piantina-rev.-3-210802-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/08/contact-dwf5-optional-piantina-rev.-3-210802-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM-025-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM-025-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM-039-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM-039-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM-041-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/KM-041-150x150.jpg',
                w: 1200,
                h: 900
            }
            ]
        };

    },

    methods: {
        file() {
            window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Contact_Dwf5_Rev-19-01-Low.pdf";
  
        }
    }
};
</script>